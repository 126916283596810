<template>
  <div class="cu-table-wrapper" :style="{height: tableHeight}">
    <div class="cu-table-container">
      <table-head
        ref="head"
        :style="{width: tableWidth}"
        :columns="columns"
        :colStyle="colStyle"
        :wrap="wrap">
      </table-head>
      <table-body
        ref="body"
        :style="{width: tableWidth, height: tableBodyHeight}"
        :columns="columns"
        :colStyle="colStyle"
        :wrap="wrap"
        :data="data">
      </table-body>
    </div>
  </div>
</template>

<script>
import TableHead from './table-head'
import TableBody from './table-body'

export default {
  components: { TableHead, TableBody },
  props: {
    columns: Array,
    data: Array,
    height: [String, Number],
    hScroll: {
      type: Boolean,
      default: false
    },
    wrap: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      colStyle: {},
      tableWidth: 'auto',
      tableHeight: 'auto',
      tableBodyHeight: 'auto',
      selectedIndex: []
    }
  },
  watch: {
    height (val) {
      this.updateHeight(val)
    },
    columns (val) {
      this.updateWidth()
    }
  },
  mounted () {
    this.updateHeight(this.height)
    this.updateWidth()
  },
  created () {
    window.addEventListener('resize', this.updateWidth, false)
  },
  beforeDestroy () {
    window.removeEventListener('resize', this.updateWidth)
  },
  methods: {
    toggleSelectAll (status) {
      this.selectedIndex = []
      if (status) {
        for (let i = 0; i < this.data.length; i++) {
          this.selectedIndex.push(i)
        }
      }
      this.$refs.body.updateAllCheckStatus(status)
    },
    toggleSelectRow (index, status) {
      let idx = this.selectedIndex.indexOf(index)
      if (status && idx < 0) {
        this.selectedIndex.push(index)
      } else if (!status && idx >= 0) {
        this.selectedIndex.splice(idx, 1)
      }
      this.selectedIndex.sort((a, b) => a - b)
      if (this.selectedIndex.length === this.data.length) {
        this.$refs.head.updateSelectAll(true)
      } else {
        this.$refs.head.updateSelectAll(false)
      }
    },
    getSelectedData () {
      let result = []
      this.selectedIndex.forEach(index => {
        let data = Object.assign({}, this.data[index])
        result.push(JSON.parse(JSON.stringify(data)))
      })
      return result
    },
    sort (colKey) {},
    updateHeight (val) {
      if (!isNaN(parseInt(val))) {
        let ht = Math.max(parseInt(val), 30)
        this.tableHeight = ht + 'px'
        this.tableBodyHeight = ht - 30 + 'px'
      }
    },
    updateWidth () {
      let colStyle = {}
      let fn = () => {
        let $tableWidth = this.$el.offsetWidth
        let timer = null
        if ($tableWidth) {
          clearTimeout(timer)
          let widthSum = 0
          this.columns.forEach(col => {
            widthSum += col.width
          })
          if (!widthSum) {
            this.tableWidth = 'auto'
          } else {
            let maxWidth = $tableWidth
            if (widthSum > $tableWidth && this.hScroll) {
              maxWidth = widthSum
            }
            this.columns.forEach(col => {
              if (col.width) {
                colStyle[col.key || col.type] = {width: (col.width / widthSum) * maxWidth + 'px'}
              }
              if (col.align) {
                colStyle[col.key || col.type] = colStyle[col.key || col.type] || {}
                colStyle[col.key || col.type]['textAlign'] = col.align
              }
            })
            this.tableWidth = maxWidth + 'px'
          }
          this.colStyle = colStyle
        } else {
          timer = setTimeout(fn, 50)
        }
      }
      fn()
    }
  }
}
</script>

<style lang="less">
.cu-table-wrapper {
  width: 100%;
  border: 1px solid #b6c3d0;
}
.cu-table-container {
  overflow: hidden;
}
.cu-table-list {
  &.head {
    background: #ebeef1;
    border-bottom: 0;
    font-size: 14px;
    overflow: hidden;
  }
  &.body {
    font-size: 13px;
    overflow: auto;
    &> .cu-table-item {
      &:nth-child(odd) {
        &:hover {
          background-color: #dce5f9;
        }
      }
      &:nth-child(even) {
        &:hover {
          background-color: #f3f3f3;
        }
      }
    }
  }
}
.cu-table-item.wrap {
  white-space: normal;
  height: 40px;
  line-height: 16px;
  position: relative;
  display: flex;
  align-items: center;
  font-size: 12px;
  &>.col {
    box-sizing: content-box;
    width: 5%;
    padding: 0 10px;
    height: 40px;
    line-height: 16px;
    white-space: normal;
    position: relative;
    overflow: auto;
    display: flex;
    align-items: center;
    &:after {
      content: "";
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      height: 1px;
      background-color: #e2e7f1;
    }
    &.index {
      padding: 0;
      justify-content: center;
    }
  }
}
.cu-table-item {
  white-space: nowrap;
  height: 30px;
  line-height: 30px;
  position: relative;
  &>.col {
    box-sizing: border-box;
    display: inline-block;
    overflow: hidden;
    width: 5%;
    padding: 0 10px;
    white-space: nowrap;
    height: 30px;
    line-height: 30px;
    position: relative;
    &:after {
      content: "";
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      height: 1px;
      background-color: #e2e7f1;
    }
  }
}
.table-actions-btn {
  border: 0;
  border-radius: 2px;
  color: #001771;
  background-color: #eee;
  outline: 0;
  padding: 2px 7px;
  transition: color, background-color 0.2s;
  cursor: pointer;
  &:hover {
    color: #0128d8;
    background-color: #e9e9e9;
  }
}
</style>
