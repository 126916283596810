<template>
  <div class="user-page">
    <y-table :columns="columns" :data="userList" :height="tableHeight"></y-table>
  </div>
</template>
<script>
import YTable from './common/table/table.vue'
import { authenticate, catchExpired } from '../utils/auth'
export default {
  components: {YTable},
  data () {
    return {
      userList: [],
      columns: [
        { title: '#', type: 'index', width: 40 },
        { title: '#', type: 'selection', width: 60 },
        { title: 'nickname', key: 'nickname', width: 150 },
        { title: 'email', key: 'email', width: 150 },
        { title: 'emailValid', key: 'emailValid', width: 150 },
        { title: 'time', key: 'time', width: 150 },
        {
          title: '操作',
          width: 150,
          render: (h, params) => {
            return h('span', [
              h('button', {
                attrs: {
                  class: 'table-actions-btn'
                },
                on: {
                  click: () => { this.valid(params.row) }
                }
              }, '验证')
            ])
          }
        }
      ]
    }
  },
  computed: {
    tableHeight () {
      return this.$store.getters.getWinHeight - 100 - (window.$upgrade ? 22 : 0)
    }
  },
  mounted () {
    this.loadUserList()
  },
  methods: {
    loadUserList () {
      this.$http.get(`${this.httpRoot}/auth/user/list/unvalid`, authenticate())
      .then(res => res.json())
      .then(res => {
        if (res.status === 'success') {
          this.userList = res.userList || ''
        }
      })
      .catch(err => {
        catchExpired(err, this)
      })
    },
    validEmail (id, email) {
      this.$http.post(`${this.httpRoot}/auth/email/valid`, {id, email}, authenticate())
      .then(res => res.json())
      .then(res => {
        if (res.status === 'success') {
          this.loadUserList()
        }
      })
      .catch(err => {
        console.log(err)
      })
    },
    valid (data) {
      const user = data
      this.validEmail(user.unionid, user.email)
    }
  }
}
</script>
<style lang="less">
.user-page {
  padding: 20px;
}
</style>
