export default {
  name: 'CellRender',
  functional: true,
  props: {
    column: Object,
    index: Number,
    row: Object
  },
  render: (h, ctx) => {
    if (ctx.props.column.render) {
      return ctx.props.column.render(h, {row: ctx.props.row, index: ctx.props.index})
    } else {
      const formatter = ctx.props.column.formatter
      const value = ctx.props.row[ctx.props.column.key]
      return h('span',
        {
          attrs: {
            class: 'value'
          }
        },
        formatter ? formatter(value) : value
      )
    }
  }
}
