<template>
  <div class="cu-table-list body">
    <div class="cu-table-item" :class="{wrap: wrap}" v-for="(one, index) in data" :key="index">
      <span class="col" :class="[col.key||col.type]" ref="col" :keyid="col.id" :style="colStyle[col.key || col.type]" v-for="col in columns" :key="col.key">
        <template v-if="col.type">
          <span v-if="col.type==='index'" class="index">{{ index + 1 }}</span>
          <span v-if="col.type==='selection'" class="selection">
            <input type="checkbox" v-model="checkSatus[index]" @change="checkChange(index)">
          </span>
        </template>
        <cell-render v-else :row="one" :index="index" :column="col"></cell-render>
      </span>
    </div>
  </div>
</template>

<script>
import CellRender from './cell-render'
export default {
  components: { CellRender },
  props: {
    columns: Array,
    data: Array,
    colStyle: {
      type: Object,
      default () { return {} }
    },
    wrap: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      checkSatus: this.getCheckStatus(this.data)
    }
  },
  watch: {
    data (val) {
      if (val) {
        this.checkSatus = this.getCheckStatus(val)
      }
    }
  },
  methods: {
    getCheckStatus (data) {
      let status = {}
      let _data = data || []
      _data.forEach((item, index) => {
        status[index] = false
      })
      return status
    },
    updateAllCheckStatus (status) {
      if (status) {
        Object.keys(this.checkSatus || {}).forEach(key => {
          this.checkSatus[key] = true
        })
      } else {
        Object.keys(this.checkSatus || {}).forEach(key => {
          this.checkSatus[key] = false
        })
      }
    },
    checkChange (index) {
      this.$parent.toggleSelectRow(index, this.checkSatus[index])
    }
  }
}
</script>
