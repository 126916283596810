<template>
  <div class="cu-table-list head">
    <div class="cu-table-item" :class="{wrap: wrap}">
      <span class="col" :class="[col.key||col.type]" :style="colStyle[col.key || col.type]" v-for="col in columns" :key="col.key">
        <template v-if="col.type">
          <span v-if="col.type==='index'" class="index title">{{ col.title || '序号' }}</span>
          <span v-if="col.type==='selection'" class="selection title">
            <input type="checkbox" v-model="selectAll" @change="checkChange">
          </span>
        </template>
        <template v-else>
          <span class="title">{{ col.title }}</span>
          <span
            v-if="col.sortable"
            class="fa"
            :class="[sortStatus[col.key]==='desc'?'fa-caret-down': 'fa-caret-up']"
            @click.stop="sort(col)">
          </span>
        </template>
      </span>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    columns: Array,
    colStyle: {
      type: Object,
      default () { return {} }
    },
    wrap: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      selectAll: false,
      sortStatus: this.getSortStatus(this.columns)
    }
  },
  watch: {
    columns (val) {
      if (val) {
        this.sortStatus = this.getSortStatus(val)
      }
    }
  },
  methods: {
    getSortStatus (columns) {
      let sortStatus = {}
      if (columns && columns.length) {
        columns.forEach(col => {
          sortStatus[col.key] = 'desc'
        })
      }
      return sortStatus
    },
    updateSelectAll (status) {
      this.selectAll = status
    },
    checkChange (event) {
      this.$parent.toggleSelectAll(this.selectAll)
    },
    sort (col) {
      if (this.sortable) {
        this.sortStatus[col.key] = this.sortStatus[col.key] === 'desc' ? 'asc' : 'desc'
        this.$parent.sort(col.key)
      }
    }
  }
}
</script>
